import { useState, useEffect } from "react";

import api from "@helpers/api";

const useDeleteNoteModal = props => {
  const { open, toggle, note, updateNotes, setServerMessage } = props;

  const [fetching, setFetching] = useState(false);

  const handleSubmit = async e => {
    try {
      setFetching(true);

      const response = await api(
        "client/notes/delete",
        { noteId: note.NoteId, advisorId: note.AdvisorId },
        {},
        true
      );
      const { success } = response;
      setFetching(false);

      if (success) {
        updateNotes({
          NoteId: note.NoteId, 
        }, "delete");
        setServerMessage({
          type: "success",
          text: "Anteckningen har tagits bort."
        });
        return toggle(null);
      } else {
        setServerMessage({
          type: "error",
          text: "Något gick fel. Kontakta supporten."
        });
        return toggle(null);
      }
    } catch (error) {
      setFetching(false);
      setServerMessage({
        type: "error",
        text: "Något gick fel. Kontakta supporten."
      });
      console.error(error);
      return toggle(null);
    }
  };

  return {
    open,
    fetching,
    toggle,
    setServerMessage,
    handleSubmit
  };
};

export default useDeleteNoteModal;
