import React from "react";

// COMPONENTS
import Checkbox from "@components/Checkbox/Checkbox";

// HELPERS
import { createClassName } from "@helpers/utils";


export default class OptionsList extends React.PureComponent {

    state = {
      filled: false,
      focused: false,
      success: false,
      invalid: false,
    };
    
    updateState = state => {
      this.setState(state);
    }

    render() {
        const { props, state, updateState } = this;
        const { style, orientation, description, options, name, size, disabled, required, type, miniNote, alignCenter, value, tooltip, validation, onChange, highlightErrors } = props;


        const classes = createClassName("Cob-OptionsList", {
            "small": size === "small",
            "vertical": orientation === "vertical",
            "focus": state.focused,
            "disabled": disabled === true,
            "filled": state.filled || !!value,
            "required": required === true,
            "tooltip": !!tooltip,
            "invalid": state.invalid,
            "success": state.success,
            "align-center": alignCenter === true
        });

        return (
            <div className={ classes } style={ style }>
                { !!description && <p className="Cob-OptionsList-description"> { description }</p> }
                { !!miniNote && <p className="Cob-OptionsList-miniNote"> { miniNote }</p> }
                <div className={`Cob-OptionsList-checkboxes ${state.invalid ? "invalid" : ""}`}>
                    { options.map((option, index) => (
                        <OptionsListCheckbox
                            key={ (option.label || "") + "-" + (option.value || "") }
                            type={ type }
                            option={ option }
                            index={ index }
                            value= {value}
                            parentName={ name }
                            parentDisabled={ disabled }
                            parentRequired={ required }
                            validation={ () => validation(value) }
                            onParentChange={ onChange }
                            updateParentState={updateState}
                            highlightErrors= {highlightErrors}
                        />
                    ))}
                </div>
                {
                state.invalid ?
                  <OptionsListStatus icon="error"/>
                  : null
                }
            </div>
        );
    }

}

// PRIVATE COMPONENTS
function OptionsListStatus({icon, requiredSymbol}) {
	return (
		<div className="Cob-OptionsList-status">
			<div className={createClassName("material-icons", {
				"icon": !!icon,
				"required-symbol": requiredSymbol === true,
			})}>{requiredSymbol === true ? "*" : icon}</div>
		</div>
	);
}

class OptionsListCheckbox extends React.PureComponent {

  componentDidMount() {
    _validationAsync.call(this);
  }

  componentDidUpdate(nextProps) {
    _validationAsync.call(this);
  }
    
    render() {
        const { props } = this;
        const { type, option, parentName, parentDisabled, parentRequired } = props;

        return (
            <Checkbox
                type={ type }
                label={ option.label }
                title={ option.title }
                value={ option.value }
                checked={ option.checked }
                name={ typeof option.name !== "undefined" ? option.name : parentName }
                disabled={ typeof option.disabled !== "undefined" ? option.disabled : parentDisabled }
                required={ typeof option.required !== "undefined" ? option.required : parentRequired }
                onChange={ this._onChange }
            />
        );
    }

    // Internal methods
    _onChange = (checked, value) => {
      const { option, index, onParentChange } = this.props;
      
      if(typeof option.onChange !== "undefined") {
          option.onChange(checked, value, index);
      } else if(onParentChange) {
          onParentChange(checked, value, index);
      }
    }
    
}

// PRIVATE FUNCTIONS
function _validationAsync() {
	return new Promise(resolve => {
		const {value, validation, highlightErrors, updateParentState} = this.props;
    
    const newState = {filled: !!value};
    
    if (value) {  
      if (validation) {
        if (validation(value) === true) {     
          newState.filled = true;
          newState.success = true;
          newState.invalid = false;
        } else {
          newState.filled = true;
          newState.success = false;
        }
      }
    }
    else {     
      newState.filled = false;
      newState.success = false;
      newState.invalid = validation && highlightErrors ? true : false;
    }

		if (updateParentState) updateParentState(newState);
	});
}