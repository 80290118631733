import { useState } from "react";

import useResponsive from "@hooks/useResponsive";
import SignedVolume from "./SignedVolume";

const useDashboardReports = props => {
  const isLaptopOrDesktop = useResponsive("up", "md");
  const [currentTab, setCurrentTab] = useState("signedVolume");

  const reports = [
    {
      key: "signedVolume",
      label: "Signerad volym",
      component: SignedVolume
    },
    {
      key: "otherReport1",
      label: "Other Report 1"
      // component: OtherReport1
    },
    {
      key: "otherReport2",
      label: "Other Report 2"
      // component: OtherReport2
    }
  ];

  const handleTabChange = (e, newValue) => {
    setCurrentTab(newValue);
  };

  return {
    reports,
    currentTab,
    handleTabChange
  };
};

export default useDashboardReports;
