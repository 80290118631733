import { useState, useEffect, useRef, useCallback } from "react";
import moment from "moment";
import api from "@helpers/api";
import { useReactToPrint } from "react-to-print";

const useSignedVolume = props => {
  const [loaded, setLoaded] = useState(false);
  const [report, setReport] = useState([]);
  const contentRef = useRef(null);
  const handlePrint = useReactToPrint({ contentRef });
  const [error, setError] = useState(null);

  // Date ranges
  const startOfLastYear = new Date(
    moment().subtract(1, "years").startOf("year")
  );
  const endOfLastYear = new Date(moment().subtract(1, "years").endOf("year"));
  const startOftheYear = new Date(moment().startOf("year"));
  const startOfCurrentQuarter = new Date(moment().startOf("quarter"));
  const endOfFirstQuarter = new Date(
    moment().startOf("year").add(2, "months").endOf("month")
  );
  const startOfSecondQuarter = new Date(
    moment().startOf("year").add(3, "months")
  );
  const endOfSecondQuarter = new Date(
    moment().startOf("year").add(5, "months").endOf("month")
  );
  const startOfThirdQuarter = new Date(
    moment().startOf("year").add(6, "months")
  );
  const endOfThirdQuarter = new Date(
    moment().startOf("year").add(8, "months").endOf("month")
  );
  const startOfLastMonth = new Date(
    moment().subtract(1, "months").startOf("month")
  );
  const endOfLastMonth = new Date(
    moment().subtract(1, "months").endOf("month")
  );
  const startOfTheMonth = new Date(moment().startOf("month"));
  const lastWeek = new Date(moment().subtract(1, "weeks"));
  const yesterday = new Date(moment().subtract(1, "days"));
  const today = new Date();
  const currentYear = moment().format("YY");

  const ranges = [
    // Last year
    {
      label: "Förra året",
      value: [startOfLastYear, endOfLastYear]
    },
    // YTD
    {
      label: "Nuvarande år",
      value: [startOftheYear, today]
    },
    //Q1
    ...(endOfFirstQuarter < today
      ? [
          {
            label: `Q1-${currentYear}`,
            value: [startOftheYear, endOfFirstQuarter]
          }
        ]
      : []),
    //Q2
    ...(endOfSecondQuarter < today
      ? [
          {
            label: `Q2-${currentYear}`,
            value: [startOfSecondQuarter, endOfSecondQuarter]
          }
        ]
      : []),
    //Q3
    ...(endOfThirdQuarter < today
      ? [
          {
            label: `Q3-${currentYear}`,
            value: [startOfThirdQuarter, endOfThirdQuarter]
          }
        ]
      : []),
    // Current quarter
    {
      label: "Nuvarande kvartal",
      value: [startOfCurrentQuarter, today]
    },
    // Last month
    {
      label: "Förra månaden",
      value: [startOfLastMonth, endOfLastMonth]
    },
    // Current month
    {
      label: "Nuvarande månad",
      value: [startOfTheMonth, today]
    },
    // Last 7 days
    {
      label: "Senaste 7 dagarna",
      value: [lastWeek, yesterday]
    }
  ];

  const [dateRange, setDateRange] = useState([lastWeek, yesterday]);

  const getPrintPageMargins = () => {
    return `@page { margin: 60px 40px !important; }`;
  };

  const handleDateChange = data => {
    if (data) {
      setDateRange([data[0], data[1]]);
    }
  };

  const fetchData = useCallback(async () => {
    setLoaded(false);

    try {
      const result = await api("/reports/signedvolume", {
        startDate: moment(dateRange[0]).format("YYYY-MM-DD"),
        endDate: moment(dateRange[1]).format("YYYY-MM-DD")
      });
      setReport(result);
      setLoaded(true);
    } catch (error) {
      setError(error.message);
      setLoaded(true);
    }
  }, [dateRange]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      fetchData();
    }
  }, [dateRange, fetchData]);

  return {
    loaded,
    error,
    ranges,
    dateRange,
    report,
    contentRef,
    handlePrint,
    handleDateChange,
    getPrintPageMargins
  };
};

export default useSignedVolume;
