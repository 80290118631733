// MODULES
import React from "react";

import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const WidgetTabs = props => {
  const { tabs, activeTab, setActiveTab } = props;

  return (
    <Tabs
      // variant="scrollable"
      aria-label="Advisors tabs"
      value={activeTab}
      onChange={(e, value) => setActiveTab(value)}
      scrollButtons={false}
      // allowScrollButtonsMobile
      sx={{          
        width: "100%",  
        minHeight: "unset",
        mb: "5px",
        "& .MuiTabs-flexContainer": {
          flexDirection: "row",
        },
        "& .MuiButtonBase-root": {
          fontFamily: "Roboto",
          fontSize: "16px",
          minHeight: "unset",
          minWidth: "100px",
          py: 0.625,
          px: 2.25,
          color: "primary.main",
          border: "2px solid",              
          borderColor: "secondary.main",
          backgroundColor: "#FFF"
        },
        "& .MuiTabs-scrollButtons.Mui-disabled": {
          opacity: 0.3
        },
        "& .Mui-selected": {
          color: "primary.main",
          backgroundColor: "secondary.main",
          border: "2px solid",
          borderColor: "secondary.main",
        },
        "& .Mui-disabled": {
          backgroundColor: "rgba(0, 63, 82, 0.5)",
          cursor: "not-allowed !important",
          pointerEvents: "auto !important",
        },
        "& .MuiTabs-indicator": {
          display: "none"
        },
        "& .MuiTabs-scroller": {
          alignSelf: "flex-start",
          width: "-webkit-fill-available"
        },
      }}
    > 
      {tabs.map((tab, index) => {
        const isFirstTab = index === 0;
        const isLastTab = index === tabs.length - 1;

        return (
          <Tab
            key={tab.key}
            disableRipple
            disableFocusRipple
            label={<Typography color="inherit" variant="button">{tab.label}</Typography>}
            value={tab.key}
            sx={{
              flexGrow: tabs.length,
              px: 0,
              height: 32,
              minHeight: "unset",
              left: !isFirstTab ? (isLastTab ? -4 : -2) : 0, //disguise double borders
              borderRadius: isFirstTab ? "6px 0 0 6px" : isLastTab ? "0 6px 6px 0" : "0",
            }}
          />            
      )})}
    </Tabs>
  );
};

export default WidgetTabs;