import React from "react";
import ReactDOM from 'react-dom';

// CLASSES
import Settings from "@classes/Settings";

// COMPONENTS
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import DashboardSessions from "../../Dashboard/DashboardSessions";
import MenuDrawer from '@components/MenuDrawer';

// HELPERS
import {createClassName, stringifyQueryParams} from "@helpers/utils";
import i18n from "@helpers/i18n";
import api from '@helpers/api';

// OTHER
import fulfillment from "./Start.fulfillment";
import InitSession from "./InitSession/InitSession";
import Validator from "../../../../../classes/Validator";
import UploadAndSign from "../../../components/UploadAndSign/UploadAndSign";
import Fullmaktskollen from "../../../components/Fullmaktskollen/Fullmaktskollen";
import DashboardPoas from "../../Dashboard/DashboardPoas";
import DashboardFutur from "../../Dashboard/DashboardFutur";
import DashboardOther from "../../Dashboard/DashboardOther";


export default class Start extends React.PureComponent {
	state = _getInitialState.call(this);

	isFulfilled(state) {
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn) {
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () => {
			this.props.setAppState({viewFulfilled: this.state.isFulfilled});
			if (callbackFn) {
				callbackFn();
			}
		});
	}

	componentDidMount() {
		const { setAppState, partner, faAuth } = this.props;

    if (partner.faSSO) {
      const faSSN = faAuth.activeUserSSN;
      if (faSSN && faSSN !== this.props.user.ssn)
        this.setCustomerState({ ssnOrg: faSSN, faRep : true });
    }

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				back: {display: false},
				next: {label: "Påbörja", disabled: true}
			}
		});
	}

	componentDidUpdate(prevProps, prevState) {
		const {props, state} = this;
		const {setAppState, user, customer, navigationButtons, partner} = props;
		const {customerState} = state;
		const updatedStateProps = {
			selectedCaseId: prevState.customerState.selectedCaseId !== state.customerState.selectedCaseId,
			isNewCase: prevState.customerState.isNewCase !== state.customerState.isNewCase,
			standaloneApplicationsChecked: prevState.customerState.standaloneApplicationsChecked !== state.customerState.standaloneApplicationsChecked
		};
		const newCustomerState = {};
		const newAppStateProps = {};

		if (!!customer && prevProps.customer !== customer) {
			newCustomerState.isCompany = customer.isCompany;
			newCustomerState.ssnOrg = customer.getData("customer_social_security_number");
		}

		if (updatedStateProps.selectedCaseId || updatedStateProps.isNewCase || updatedStateProps.standaloneApplicationsChecked) {
			newAppStateProps.navigationButtons = {
				...navigationButtons,
				next: {
					...navigationButtons.next,
					disabled: !!customerState.selectedCaseId === customerState.isNewCase && customerState.standaloneApplicationsChecked.length === 0,
					willSubmitForm: customerState.standaloneApplicationsChecked.length === 0,
					onClick: customerState.standaloneApplicationsChecked.length === 0 ? undefined : () => {

						let standaloneApp = null;
						for (let i = 0; i < partner.standaloneApplications.length; i++) {
							if (partner.standaloneApplications[i].label === customerState.standaloneApplicationsChecked[0]) {
								standaloneApp = partner.standaloneApplications[i];
								break;
							}
						}

						if (standaloneApp === null) {
							console.error("Invalid app configuration", customerState.standaloneApplicationsChecked);
							return;
						}

						let partnerString = JSON.stringify({
							partner: user.getData(),
							customer: {id: customerState.ssnOrg}
						});

						/**
						 * GLOF-1017 If the partner name or data contains non-latin1 characters we'll get an error.
						 * A workaround for this is to encode the string before encoding the string...
						 */
						let b64 = btoa(unescape(encodeURIComponent(partnerString)));
						window.location.href = standaloneApp.url + stringifyQueryParams({
							context: b64,
							token: Settings.get("AUTH_TOKEN"),
							key: standaloneApp.key
						});
					}
				}
			};
		}

		if (Object.keys(newCustomerState).length > 0) {
			this.setState({isLoading: false}, () => this.setCustomerState(newCustomerState));
		}

		if (Object.keys(newAppStateProps).length > 0) {
			setAppState(newAppStateProps);
		}
	}

	render() {
		const {props, state, setCustomerState} = this;
		const {user, advisor, partner, assentlyStatus, cobForm, faAuth} = props;
		const {customerState} = state;
		const classes = createClassName("Cob-view Cob-view1 start", {});

		if (state.isLoading) {
			return <ActivityIndicator busy/>;
		}

		if (state.dashboardSelected === "sessions") {
			return <DashboardSessions user={user} advisor={advisor} partner={partner} cobForm={cobForm} faAuth={faAuth} onToggle={this._onToggleDashboard}/>;
		}

		if (state.dashboardSelected === "poas") {
			return <DashboardPoas user={user} advisor={advisor} partner={partner} cobForm={cobForm} faAuth={faAuth} onToggle={this._onToggleDashboard}/>;
		}

		if (state.dashboardSelected === "futur") {
			return <DashboardFutur user={user} advisor={advisor} partner={partner} onToggle={this._onToggleDashboard}/>;
		}

    if (state.dashboardSelected === "other")
		{ // New implementation f6b8c12: GLOF-1711 - Might need work !
      // Do we need these checks here?? (these have been removed for other partners)
			return <DashboardOther user={user} advisor={advisor} partner={partner} onToggle={this._onToggleDashboard}/>;
		}

		const nextStepOptions = [
			{
				label: "Skapa ett nytt rådgivningstillfälle",
				checked: customerState.standaloneApplicationsChecked.length === 0 && customerState.isNewCase === true
			},
			{
				label: "Hämta ett tidigare rådgivningstillfälle",
				checked: customerState.standaloneApplicationsChecked.length === 0 && customerState.isNewCase === false,
				hiddenInModal: true
			}
		];

		/**
		 * Add links to all standalone applications (autogiro, kontoöppning, futur etc)
		 */
		for (let i = 0; i < partner.standaloneApplications.length; i++) {
			const standaloneApplication = partner.standaloneApplications[i];

			let optionDisabled = false;
			if ("disableRule" in standaloneApplication) {
				if (standaloneApplication.disableRule === 'Always') {
					optionDisabled = true;
				}
				else if (standaloneApplication.disableRule === 'FuturCode') {

					/**
					 * If the logged in user does not have a FuturCode we disable this option
					 */
					optionDisabled = user.data['futurCode'] === null || user.data['futurCode'] === '';

					/**
					 * If we have a FuturCode we check if the ssn entered was for a private individual or a company.
					 * At the moment only companies are permitted to open accounts.
					 */
					if (!optionDisabled && !customerState.isCompany) {
						optionDisabled = true;
					}
				}
				else {
					console.error("Unhandled disable rule for option ", standaloneApplication);
				}
			}

			nextStepOptions.push({
				label: standaloneApplication.label,
				checked: customerState.standaloneApplicationsChecked.includes(standaloneApplication.label),
				disabled: optionDisabled
			});
		}

		return (
      <div className={classes}>
        <MenuDrawerPortalWrapper 
          dashboardSelected={state.dashboardSelected}
          user={user}
          modules={partner.modules}
          onToggle={this._onToggleDashboard}          
          setCaseFilter={this._setCaseFilter}          
        />
        {state.isLoading ? (
          <ActivityIndicator busy style={{ left: "-6vw" }} />
        ) : state.dashboardSelected === "dashboard" ? (
          <DashboardSessions
            user={user}
            advisor={advisor}
            partner={partner}
            cobForm={cobForm}
            caseFilter={state.caseFilter || 'draft'}
            setCaseFilter={this._setCaseFilter}
          />
        ) : state.dashboardSelected === "dashboardPoas" ? (
          <DashboardPoas
            user={user}
            advisor={advisor}
            partner={partner}
            cobForm={cobForm}
            caseFilter={state.caseFilter || 'sent'}
            setCaseFilter={this._setCaseFilter}
          />
        ) : state.dashboardSelected === "dashboardFutur" ? (
          <DashboardFutur
            user={user}
            advisor={advisor}
            partner={partner}
            caseFilter={state.caseFilter || 'sent'}
            setCaseFilter={this._setCaseFilter}
          />
        ) : state.dashboardSelected === "dashboardOther" ? (// New implementation f6b8c12: GLOF-1711 - Might need work !
          <DashboardOther
            user={user}
            advisor={advisor}
            partner={partner}
            caseFilter={state.caseFilter || 'sent'}
            setCaseFilter={this._setCaseFilter}
          />
        ) : (
          <>
            {/* ToAsk: Do we want to display these in order of startViewWidgets with a for loop? */}
            {partner.startViewWidgets.includes("InitSession") && (
              <InitSession
                user={user}
                advisor={advisor}
                partner={partner}
                customerState={customerState}
                setCustomerState={setCustomerState.bind(this)}
                assentlyStatus={assentlyStatus}
                nextStepOptions={nextStepOptions}
              />
            )}
            {/* Todo: Optimise styles below once we have a better understanding of the final design */}
            <div style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "flex-start"}}>
              {partner.startViewWidgets.includes("UploadAndSign") && user.hasPermission("standalone.send") && (
                <UploadAndSign
                  widget={true}
                  width={50} // percentage value, default is 100
                  partnerSsn={this.props.user.ssn}
                />
              )}
              {partner.startViewWidgets.includes("Fullmaktskollen") && user.hasPermission("fmk.send") && (
                <Fullmaktskollen
                  widget={true}
                  width={50} // percentage value, default is 100
                  customerIdentityNumber={this.state.customerState.ssnOrg}
                  user={this.props.user}
                />
              )}
            </div>
          </>
        )}
      </div>
		);
	}

	_onToggleDashboard = option => {
		this.setState({dashboardSelected: option});
	};

  _setCaseFilter = filter => {
    this.setState({caseFilter: filter});
  }
}


// PRIVATE FUNCTIONS
function _getInitialState() {
	const {customer, partner} = this.props;
	let newState = getViewInitialStateForCustomer(customer, partner);
	newState.isFulfilled = this.isFulfilled(newState);
	return newState;
}

export function getViewInitialStateForCustomer(customer, partner) {
	const initialState = {
		isLoading: !customer,
		dashboardSelected: "start",
		isFulfilled: false,
		customerState: {
			isCompany: customer ? customer.isCompany : false,
      		faRep: false,
			ssnOrg: (!!customer && customer.getData("customer_social_security_number")) || "",
			isNewCase: null,
			casesLoading: false,
			cases: null,
			selectedCaseId: null,
			selectedCaseIndex: -1,
			standaloneApplicationsChecked: []
		}
	};

	if (partner != null && !partner.modules.includes("dashboard")) {
		initialState.dashboardSelected = "start";
	}

	return initialState;
}

// Create a wrapper outside the main React component hierarchy using React Portals
const MenuDrawerPortalWrapper = (props) => {
  return ReactDOM.createPortal(
    <MenuDrawer 
      dashboardSelected={props.dashboardSelected}
      user={props.user}
      modules={props.modules}
      onToggle={props.onToggle}
      setCaseFilter={props.setCaseFilter}
    />,
    // document.body // Appends to the body, making it relative to the whole page
    document.getElementById('leApp')
  );
};