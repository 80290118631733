import React from "react";
import moment from "moment";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import DashboardPaper from "@mui-theme/styled/DashboardPaper";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";

import ContentBox from "@components/ContentBox/ContentBox";
import searchIcon from "@assets/img/search_icon.svg";

import useCustomerNotes from "./useCustomerNotes";
import EditNoteModal from "./EditNoteModal";
import DeleteNoteModal from "./DeleteNoteModal";

const CustomerNotes = props => {
  const {
    loaded,
    user,
    notes,
    copyList,
    note,
    setNote,
    selectedNote,
    setSelectedNote,
    updateNotes,
    inputErrors,
    isAddingNote,
    handleSubmit,
    serverMessage,
    setServerMessage,
    requestSearch,
    editNoteModalOpen,
    toggleEditNoteModal,
    deleteNoteModalOpen,
    toggleDeleteNoteModal
  } = useCustomerNotes(props);

  return (
    <ContentBox
      style={{
        minHeight: "66vh",
        maxHeight: "80vh",
        overflow: "auto",
        boxShadow: "none",
        marginTop: 0,
        marginBottom: 0
      }}
    >
      {!loaded ? (
        <Box
          sx={{
            width: "100%",
            alignItems: "center"
          }}
        >
          <ActivityIndicator color="#4cb7d5" busy />
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            alignItems: "flex-start"
          }}
        >
          <Box>
            <Typography
              variant="h6"
              sx={{
                mb: 1
              }}
            >
              Anteckningar
            </Typography>
          </Box>
          <Box
            sx={{
              height: "auto",
              width: "100%",
              justifyContent: "center",
              alignItems: "flex-start",
              display: "flex",
              flexFlow: "column"
            }}
          >
            <TextField
              value={note || ""}
              placeholder={"Skriv din anteckning här"}
              required
              fullWidth
              multiline
              rows={6}
              id="note"
              name="note"
              autoComplete="note"
              error={!!inputErrors?.note}
              onChange={e => {
                if (inputErrors?.note) {
                  inputErrors.note = null;
                }
                setNote(e && e.target && e.target.value);
              }}
              onFocus={() => {
                if (serverMessage) {
                  setServerMessage(null);
                }
              }}
              helperText={inputErrors?.note}
              sx={{
                mb: 2
              }}
            />
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <CircularProgress
                color="primary"
                sx={{
                  display: isAddingNote ? "flex" : "none"
                }}
              />
              <Typography
                color="primary"
                sx={{
                  display: isAddingNote ? "flex" : "none"
                }}
              >
                Lägger till anteckning...
              </Typography>
              <Alert
                severity={
                  serverMessage?.type === "success" ? "success" : "error"
                }
                sx={{
                  display: serverMessage && !isAddingNote ? "flex" : "none",
                  width: "100%",
                  mb: 2,
                  py: 0
                }}
              >
                {serverMessage?.text}
              </Alert>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start"
              }}
            >
              <Button
                type="submit"
                variant="contained"
                onClick={handleSubmit}
                sx={{
                  mt: 0,
                  mb: 2
                }}
              >
                Lägg till anteckning
              </Button>
            </Box>
          </Box>
          {/* Notes */}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              mt: 2
            }}
          >
            {copyList && copyList.map((entry, index) => (
              <DashboardPaper
	              key={index}
	              sx={{
		              width: "100%",
		              marginTop: 1.5,
		              marginBottom: 3,
		              flexDirection: "column",
		              alignItems: "flex-start",
		              justifyContent: "center",
		              padding: "0",
		              overflow: "hidden",
		              borderRadius: "5px",
		              boxShadow: "none",
		              border: "1px solid #F0F4FF"
	              }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                      background: "#F0F4FF",
                      padding: "12px"
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "50%"
                    }}
                  >
                    <Typography variant="body3" sx={{fontWeight: "bold", color: "#2E457E"}}>
                      {entry.AdvisorFirstName} {entry.AdvisorLastName}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      alignItems: "flex-end",
                      justifyContent: "flex-start"
                    }}
                  >
                    <Typography variant="body3">
                      {moment(entry.Created).format("YYYY-MM-DD HH:mm")}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{padding: "12px"}}>
                  <Typography
                    variant="body1"
                  >
                    {entry.Note}
                  </Typography>
                </Box>
                {entry.AdvisorId === user?.ssn && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
	                    padding: "10px"
                    }}
                  >
                    <Link
                      sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
                      onClick={() => toggleEditNoteModal(entry)}
                    >
                      <Typography variant="body3" color="primary.light">
                        Ändra
                      </Typography>
                    </Link>
                    <Typography variant="body3" color="primary.light">
                      {"\u00A0/\u00A0"}
                    </Typography>
                    <Link
                      sx={{ whiteSpace: "nowrap", cursor: "pointer" }}
                      onClick={() => toggleDeleteNoteModal( entry)}
                    >
                      <Typography variant="body3" color="primary.light">
                        Ta bort
                      </Typography>
                    </Link>
                  </Box>
                )}
              </DashboardPaper>
            ))}
            {copyList.length === 0 && (
              <Typography variant="body1">
                Inga anteckningar hittades.
              </Typography>
            )}
          </Box>
        </Box>
      )}
      {editNoteModalOpen && (
        <EditNoteModal
          open={editNoteModalOpen}
          toggle={toggleEditNoteModal}
          note={selectedNote}
          updateNotes={updateNotes}
          setServerMessage={setServerMessage}
        />
      )}
      {deleteNoteModalOpen && (
        <DeleteNoteModal
          open={deleteNoteModalOpen}
          toggle={toggleDeleteNoteModal}
          note={selectedNote}
          updateNotes={updateNotes}
          setServerMessage={setServerMessage}
        />
      )}
    </ContentBox>
  );
};

export default CustomerNotes;
