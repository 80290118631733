import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";

const ModalContentBox = forwardRef((props, ref) => {
  return (
    <Box
      {...(!props?.override && props)} // override needs string "true" or ommit (false)
      ref={ref}
      sx={{
        // do not use shorthand on overrides, props need to match exactly!
        position: "absolute",
        top: "50%",
        left: "50%",
        justifyContent: "space-between",
        width: { xs: "90vw", sm: "50vw" },
        maxWidth: 800,
        height: "auto",
        bgcolor: "background.paper",
        border: 0,
        borderRadius: 2,
        boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.25)",
        paddingTop: 2,
        paddingBottom: 2,
        textAlign: "center",
        outline: "none",
        overflow: "auto",  
        transform: "translate(-50%, -50%)",
        ...props.sx
      }}
      // {...(props.override && props)}
    >
      {props.children}
    </Box>
  );
});
ModalContentBox.displayName = "ModalContentBox";

export default ModalContentBox;

ModalContentBox.propTypes = {
  override: PropTypes.string,
  ref: PropTypes.object,
  innerRef: PropTypes.object,
  sx: PropTypes.object,
  children: PropTypes.node
};
