import React, { Fragment } from "react";

import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ArrowDropUp from "@mui/icons-material/ArrowDropUp";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

import useCustomerMenuDrawer from "./useCustomerMenuDrawer";

const CustomerMenuDrawer = props => {
  const {
    open,
    // height,
    nestedItems,
    customProps,
    selectedIndex,
    selectedChild,
    handleItemClick,
    handleChildClick,
    menuItems,
    totalNotes,
    toggleDrawer
  } = useCustomerMenuDrawer(props);

  const list = () => (
    <Box role="presentation" sx={{ width: "100%", p: 0 }}>
      <List sx={{ width: "100%", mt: 0, ml: 1.5, p: 0 }}>
        {menuItems.map((item, index) => (
          <Fragment key={item.label}>
            <ListItem disablePadding>
              <ListItemButton
                disableRipple
                sx={[
                  {
                    flexDirection: "row",
                    width: "95%",
                    alignItems: "flex-start",
                    color: "common.black",
                    backgroundColor:
                      selectedIndex === index ? "tertiary.dark" : "unset",
                    borderRadius: 2,
                    px: 1,
                    m: 0.5,
                    mt: 0,
                  },
                  {
                    "&:hover":{
                      backgroundColor: "tertiary.main"
                    }
                  }
                ]}
                onClick={handleItemClick(item, index)}
              >
                {item.icon && (
                  <ListItemIcon
                    variant="active"
                    sx={{
                      alignItems: "flex-start",
                      "& .MuiSvgIcon-root": { mt: 0.2, fontSize: "1.4rem"} 
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>                  
                )}
                <ListItemText
                  primary={item.label}
                  primaryTypographyProps={{
                    variant: "CustomerMenuDrawer",
                    ...(selectedIndex === index && { sx: { alignItems: "flex-start", color: "inherit", fontWeight: 700 } })
                  }}
                  sx={{ alignItems: "flex-start", fontSize: "12px"}}
                />
                {item.name === "notes" &&
                  totalNotes > 0 &&
                  (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        backgroundColor: selectedIndex === index ? "common.white" : "tertiary.dark",
                        color: "common.black",
                        fontSize: "8px",
                        fontWeight: 400,
                        ml: 1,
                        mt: 0.4
                      }}
                    >
                      {totalNotes}
                    </Box>
                  )}
                {item?.children &&
                  item?.children.length > 0 &&
                  (nestedItems[item.name] ? (
                    <ArrowDropUp />
                  ) : (
                    <ArrowDropDown />
                  ))}
              </ListItemButton>
            </ListItem>
            {item?.children && item?.children.length > 0 && (
              <Collapse
                in={nestedItems[item.name]}
                timeout="auto"
                sx={{ width: "100%", p: 0 }}
                unmountOnExit
              >
                <List component="div" sx={{ width: "100%", p: 0 }}>
                  {item?.children.map((child, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemButton
                        disableRipple
                        sx={[
                          {
                            flexDirection: "row",
                            width: "100%",
                            alignItems: "flex-start",
                            pl: 2.8,
                            borderBottom: 1,
                            borderColor: "grey.400",
                            backgroundColor:
                              selectedChild === child.name
                                ? "transparent"
                                : "secondary.main"
                          },
                          {
                            "&:hover": {
                              backgroundColor: "tertiary.main"
                            }
                          }
                        ]}
                        onClick={handleChildClick(child, index)}
                      >
                        <ListItemText
                          primary={child.label}
                          primaryTypographyProps={{
                            variant: "CustomerMenuDrawer",
                            sx: {
                              ...(selectedChild === child.name && {
                                  color: "inherit",
                                  fontWeight: 800
                                }),
                              fontSize: "0.8em !important",
                              ml: 3,
                              whiteSpace: "nowrap",
                              maxWidth: 150,
                              overflow: "hidden",
                              textOverflow: "ellipsis"
                            }
                          }}
                          sx={{ alignItems: "start"}}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            )}
          </Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <SwipeableDrawer
      PaperProps={{
        sx: {
          position: { md: "relative" },
          width: { xs: "65%", md: "100%" },
          overflow: "auto",
          borderRightColor: "gray.200",
          backgroundColor: "#FFF",
          pr: 2,
          mr: 4,
          zIndex: 999
        }
      }}
      anchor="left"
      open={open}
      onClose={toggleDrawer()}
      onOpen={toggleDrawer()}
      sx={{
        height: "100%",
        position: "relative",
        left: 0,
        width: { md: "180px" },
        zIndex: 999
      }}
      {...customProps}
    >
      {list()}
    </SwipeableDrawer>
  );
};

export default CustomerMenuDrawer;
