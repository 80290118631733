// HELPERS
import api from "@helpers/api";

/**
 * Class representing and holding partner specific app configuration. This includes the logo, background image
 * and an array of the views that should be available in the advisory wizard.
 */
export default class Partner {

	_name = null;
	_data = null;

	constructor(name) {
		this._name = name;
	}

	async loadData() {
		this._data = await api("partner/data", {name: this.name, authToken: null});
	}

	// Properties
	get name() {
		return this._name;
	}
  
	get partnerId() {
    return _getData.call(this, "partnerId");
	}
  
  get logoCompanyName() {
    return _getData.call(this, "logoCompanyName");
  }

  get theme() {
    return _getData.call(this, "theme");
  }

	get authenticationProvider() {
		return _getData.call(this, "authenticationProvider");
	}

	get language() {
		return _getData.call(this, "language");
	}

	get documentTitle() {
		return _getData.call(this, "documentTitle");
	}

	get backgroundImage() {
		return _getData.call(this, "backgroundImage");
	}

	get logo() {
		return _getData.call(this, "logo");
	}

	get logoHeight() {
		return _getData.call(this, "logoHeight");
	}

	get standaloneApplications() {
		return _getData.call(this, "standaloneApplications");
	}

	get favicon() {
		return _getData.call(this, "favicon");
	}

	get workflows() {
		return _getData.call(this, "workflows");
	}

	get startView() {
		return _getData.call(this, "startView");
	}

	get headerWidgets() {
		return _getData.call(this, "headerWidgets");
	}

	get allowNoCustomerId() {
		return _getData.call(this, "allowNoCustomerId");
	}

	get customerModalWidgets() {
		return _getData.call(this, "customerModalWidgets");
	}

	get signView() {
		return _getData.call(this, "signView");
	}

	get modules() {
		return _getData.call(this, "modules");
	}

	get orderinstruktion() {
		return _getData.call(this, "orderinstruktion");
	}

	get futurcase() {
		return _getData.call(this, "futurcase");
	}

	get iframed() {
		return _getData.call(this, "iframed");
	}

	get faSSO() {
		return _getData.call(this, "faSSO");
	}

	async setupDocument() {
		// Set page title
		if (this.documentTitle) {
			document.title = this.documentTitle;
		}

		// Set favicon
		if (this.favicon) {
      const favicon = await import(`../assets/${this.name}/${this.favicon}.png`);
      const faviconUrl = favicon.default;
      let faviconElement = document.head.querySelector("#app-favicon");

      if (faviconElement) {
        faviconElement.href = faviconUrl;
      }
      else {
        faviconElement = document.createElement("link");
        faviconElement.id = "app-favicon";
        faviconElement.rel = "shortcut icon";
        faviconElement.href = faviconUrl;
        document.head.appendChild(faviconElement);
      }
		}
	}
}

function _getData(key) {
	return (this._data && this._data[key]) || null;
}