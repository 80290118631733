import React, {Fragment} from "react";

import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

const ButtonAdd = props =>
{
	return (
		<Link component="button"
		      type="button"
		      onClick={props.onClick}
		      sx={{
			      display: "flex",
			      flexDirection: "row",
			      pb: 0.125,
			      textDecoration: "none",
			      fontSize: "13px",
			      fontWeight: "bold",
			      color: "button.main",
			      cursor: "pointer"
		      }}>
			<Box component="span"
			     sx={{
				     display: "flex",
				     fontSize: "15px",
				     fontStyle: "normal",
				     lineHeight: "17px",
				     color: "#FFF",
				     marginRight: "4px",
				     border: "1px solid",
				     borderRadius: "50%",
				     width: "18px",
				     height: "18px",
				     justifyContent: "center",
				     alignItems: "center",
				     backgroundColor: "button.main",
				     paddingBottom: "1px"
			     }}>
				+
			</Box>
			{props.label}
		</Link>
	);
};

export default ButtonAdd;
