import { useState } from "react";

import {getObjectKeyByValue} from "@helpers/utils";
import useResponsive from "@hooks/useResponsive";
import { MenuDrawerConsumer } from "@contexts/MenuDrawerProvider";

const useMenuDrawer = props => {  
  const isLaptopOrDesktop = useResponsive("up", "md");
  const menuDrawerCtxt = MenuDrawerConsumer();
  const open = isLaptopOrDesktop ? true : menuDrawerCtxt.state.open;

  const {menuItems, currentView, totalNotes, onToggle, handleViewChange} = props;
  // Find the index in menuItems array of the current view and set it as the selectedIndex
  const index = menuItems.findIndex(item => item.name === currentView);
  const [selectedIndex, setSelectedIndex] = useState(index);

  const [selectedChild, setSelectedChild] = useState({});
  const [nestedItems, setNestedItems] = useState({});

  const customProps = {
    variant: isLaptopOrDesktop ? "permanent" : "temporary"
  };
  
  const toggleDrawer = () => e => {
    if (
      isLaptopOrDesktop ||
      (e && e.type === "keydown" && (e.key === "Tab" || e.key === "Shift"))
    ) {
      return;
    }
    return menuDrawerCtxt.toggleDrawer();
  };

  // Handle parent menu item click
  const handleItemClick = item => e => {
    e.preventDefault;
    if (item.name !== getObjectKeyByValue(nestedItems, true)) {
      // if it's not a currently opened nested item
      setNestedItems({});
      item.handleNavigation();
      if (item.children)
        setNestedItems(prevState => ({
          ...prevState,
          [item.name]: !prevState[item.name]
        }));
    }
    // reset case filter (force using default tab)
    handleViewChange(null, item.name);
    if (isLaptopOrDesktop) return;
    return menuDrawerCtxt.toggleDrawer();
  };

  // Handle nested children click
  const handleChildClick = child => e => {
    e.preventDefault;
    child.handleNavigation();
    if (isLaptopOrDesktop) return;
    return menuDrawerCtxt.toggleDrawer();
  };

  if (menuItems && menuItems.length > 0) {
    menuItems.forEach((item, index) => {
      item.handleNavigation = () => {
        setSelectedIndex(index);
        return onToggle(item.key);
      }
    });
  }

  return {
    open,
    customProps,
    nestedItems,
    selectedIndex,
    selectedChild,
    handleItemClick,
    handleChildClick,
    menuItems,
    totalNotes,
    toggleDrawer
  };
};

export default useMenuDrawer;
