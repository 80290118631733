import React from "react";
import PropTypes from "prop-types";
import LoadingButton from '@mui/lab/LoadingButton';

const ThemedButton = props => {
  const { override, variant, sx, children } = props;
  
  return (
    <LoadingButton
      {...(!override && props)} // override needs string "true" or ommit (false)
      disableElevation
      sx={{
        // do not use shorthand on overrides, props need to match exactly!
        width: "auto",
        minWidth: 150,
        marginTop: 1,
        marginBottom: 1,
        border: 1,
        borderStyle: "solid",
        ...(variant === "contained" && { 
          backgroundColor: "button.main",
          "&:hover": {
            backgroundColor: "button.main"
          },
        }),
        fontSize: "12px",
        weight: 5000,
        textTransform: "uppercase",
        ...sx
      }}
      {...(override && props)}
    >
      {children}
    </LoadingButton>
  );
}
  

export default ThemedButton;

ThemedButton.propTypes = {
  override: PropTypes.string,
  disabled: PropTypes.bool,
  filled: PropTypes.bool,
  sx: PropTypes.object,
  children: PropTypes.node
};
