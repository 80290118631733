import React, {Fragment} from "react";
import ReactDOM from 'react-dom';

// CLASSES
import Settings from "@classes/Settings";

// COMPONENTS
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import MenuDrawer from '@components/MenuDrawer';

// HELPERS
import {md5, createClassName, stringifyQueryParams, formatSSN} from "@helpers/utils";

// OTHER
import DashboardSessions from "../../Dashboard/DashboardSessions";
import DashboardFutur from "../../Dashboard/DashboardFutur";
import DashboardPoas from "../../Dashboard/DashboardPoas";
import DashboardKycs from "../../Dashboard/DashboardKycs";
import DashboardOther from "../../Dashboard/DashboardOther";

import fulfillment from "./Start.fulfillment";
import InitSession from "./InitSession/InitSession";
import CustomersTable from "../../../components/CustomersTable";
import UploadAndSign from "../../../components/UploadAndSign/UploadAndSign";
import Fullmaktskollen from "../../../components/Fullmaktskollen/Fullmaktskollen";



export default class Start extends React.PureComponent
{
	state = _getInitialState.call(this);

	isFulfilled(state)
	{
		return fulfillment(state, this.state);
	}

	setCustomerState(customerState, callbackFn)
	{
		customerState = {...this.state.customerState, ...customerState};
		this.setState({
			customerState,
			isFulfilled: this.isFulfilled({customerState})
		}, () =>
		{
      // TODO: refactor below to appCustomerState to avoid confusions
			this.props.setAppState({customerState, viewFulfilled: this.state.isFulfilled});
			if (callbackFn)
			{
				callbackFn();
			}
		});
	}

	componentDidMount()
	{
		const {setAppState} = this.props;

		// Set navigation events
		setAppState({
			viewFulfilled: this.isFulfilled(),
			showValidationWarning: false,
			navigationButtons: {
				sharing: {},
				back: {display: false},
				next: {label: "Påbörja", disabled: true}
			}
		});
	}

	componentDidUpdate(prevProps, prevState)
	{
		const {props, state} = this;
		const {setAppState, user, customer, navigationButtons, partner} = props;
		const {customerState} = state;
		const updatedStateProps = {
			selectedCaseId: prevState.customerState.selectedCaseId !== state.customerState.selectedCaseId,
			caseListFiltering: prevState.customerState.caseListFiltering !== state.customerState.caseListFiltering,
			standaloneApplicationsChecked: prevState.customerState.standaloneApplicationsChecked !== state.customerState.standaloneApplicationsChecked
		};

		const newCustomerState = {};
		const newAppStateProps = {};

		if (!!customer && prevProps.customer !== customer)
		{
			newCustomerState.isCompany = customer.isCompany;
			newCustomerState.ssnOrg = customer.getData("customer_social_security_number");
		}

		if (updatedStateProps.selectedCaseId || updatedStateProps.caseListFiltering || updatedStateProps.standaloneApplicationsChecked)
		{
			let startButtonDisabled = false;
			if (customerState.selectedOption === "new" || customerState.selectedOption === "futur")
			{
				startButtonDisabled = false;
			}
			else if (customerState.selectedOption === "drafts" || customerState.selectedOption === "sent-or-signed")
			{
				startButtonDisabled = customerState.selectedCaseId === null;
			}
			else if (customerState.standaloneApplicationsChecked.length === 0)
			{
				startButtonDisabled = true;
			}

			newAppStateProps.navigationButtons = {
				...navigationButtons,
				next: {
					...navigationButtons.next,
					disabled: startButtonDisabled,
					willSubmitForm: customerState.standaloneApplicationsChecked.length === 0,
					onClick: customerState.standaloneApplicationsChecked.length === 0 ? undefined : () =>
					{
						let standaloneApp = null;
						for (let i = 0; i < partner.standaloneApplications.length; i++)
						{
							if (partner.standaloneApplications[i].label === customerState.standaloneApplicationsChecked[0])
							{
								standaloneApp = partner.standaloneApplications[i];
								break;
							}
						}

						if (standaloneApp === null)
						{
							console.error("Invalid app configuration", customerState.standaloneApplicationsChecked);
							return;
						}
            
						if (customerState.ssnOrg === null)
						{
							console.error("Cannot proceed to standalone application without a customer ssn");
							return;
						}

						// Create a checksum that the cob odin client will check to make sure that the request
						// originated in a correct way.
						let dateString = new Date()
						dateString = dateString.toISOString().split('T')[0];
						dateString = dateString + new Date().getHours() + ":00";
						let checkString = md5(formatSSN(customerState.ssnOrg, {withDash: false}) + dateString + "citroneerclient");
						let partnerString = JSON.stringify({
							partner: user.getData(),
							customer: {id: formatSSN(customerState.ssnOrg, {withDash: false}), check: checkString}
						});

						/**
						 * GLOF-1017 If the partner name or data contains non-latin1 characters we'll get an error.
						 * A workaround for this is to encode the string before encoding the string...
						 */
						let b64 = btoa(unescape(encodeURIComponent(partnerString)));
						window.location.href = standaloneApp.url + stringifyQueryParams({
							context: b64,
							token: Settings.get("AUTH_TOKEN"),
							key: standaloneApp.key
						});
					}
				}
			};
		}

		if (Object.keys(newCustomerState).length > 0)
		{
			this.setState({isLoading: false}, () => this.setCustomerState(newCustomerState));
		}

		if (Object.keys(newAppStateProps).length > 0)
		{
			setAppState(newAppStateProps);
		}
	}

	render()
	{
		const {props, state, setCustomerState} = this;
		const {busy, user, advisor, partner, customers, assentlyStatus, cobForm, onWorkflowSwitched} = props;
		const {customerState, dashboardSelected} = state;
		const classes = createClassName("Cob-view Cob-view1 start", {});

		const nextStepOptions = [];
		if (user.hasPermission("case.create"))
		{
			nextStepOptions.push({
				label: "Ny rådgivning",
				checked: customerState.selectedOption === "new"
			});
		}

		if (user.hasPermission("case.edit"))
		{
			nextStepOptions.push({
				label: "Ny rådgivning baserad på tidigare uppgifter",
				checked: customerState.selectedOption === "sent-or-signed",
				hiddenInModal: true
			});

			nextStepOptions.push({
				label: "Fortsätt påbörjad rådgivning",
				checked: customerState.selectedOption === "drafts",
				hiddenInModal: true
			});
		}

		const futurDisabled = user.data['futurCode'] === null || user.data['futurCode'] === '';

		nextStepOptions.push({
			label: "Öppna depå inför tecknade av kapitalförsäkring",
			checked: customerState.selectedOption === "futur",
			disabled: customerState.casesLoading || futurDisabled,
			hiddenInModal: true
		});

		/**
		 * Add links to all standalone applications (autogiro, kontoöppning, futur etc)
		 */
		for (let i = 0; i < partner.standaloneApplications.length; i++)
		{
			const standaloneApplication = partner.standaloneApplications[i];

			let optionDisabled = false;
			if ("disableRule" in standaloneApplication)
			{
				if (standaloneApplication.disableRule === 'Always')
				{
					optionDisabled = true;
				}
				else if (standaloneApplication.disableRule === 'FuturCode')
				{
					/**
					 * If the logged in user does not have a FuturCode we disable this option
					 */
					optionDisabled = user.data['futurCode'] === null || user.data['futurCode'] === '';

					/**
					 * If we have a FuturCode we check if the ssn entered was for a private individual or a company.
					 */
					if (!optionDisabled)
					{
						if (customerState.isCompany)
						{
							let orgNrNotAktiebolag = true;
							if (customerState.ssnOrg && customerState.ssnOrg.length > 3)
                {
                  if (customerState.ssnOrg.substring(0, 1) === "5" ||
                    (customerState.ssnOrg.substring(0, 2) === "16" &&
                      customerState.ssnOrg.substring(3, 4) === "5"))
								{
									orgNrNotAktiebolag = false;
								}
							}
							optionDisabled = orgNrNotAktiebolag;
						}
						else // Allow also private individuals
						{
							optionDisabled = false;
						}
					}
				}
				else
				{
					console.error("Unhandled disable rule for option ", standaloneApplication);
				}
			}

			nextStepOptions.push({
				label: standaloneApplication.label,
				checked: customerState.standaloneApplicationsChecked.includes(standaloneApplication.label),
				disabled: optionDisabled,
				hiddenInModal: true
			});
		}

    const dashboards = {
      DashboardSessions: {
        component: DashboardSessions,
      },
      DashboardPoas: {
        component: DashboardPoas,
      },
      DashboardFutur: {
        component: DashboardFutur,
      },
      DashboardKycs: {
        component: DashboardKycs,
      },
      DashboardOther: {
        component: DashboardOther,
      },
    };

    const dashboardWidgets = partner.modules.find(module => module.key === dashboardSelected)?.widgets;

    return (      
      <div className={classes}>
        <MenuDrawerPortalWrapper 
          dashboardSelected={dashboardSelected}
          user={user}
          modules={partner.modules}
          onToggle={this._onToggleDashboard}          
          setCaseFilter={this._setCaseFilter}          
        />
        {state.isLoading ? (
          <ActivityIndicator color={partner?.theme.colors.primary.main} busy style={{ left: "-12.5%" }} />
        ) : (
          <div className="inline-widget">
            {dashboardWidgets && dashboardWidgets.map((widget, index) => {              
              const DashboardWidget = dashboards[widget.component]?.component;

              return (
                <Fragment key={index}>
                    {DashboardWidget && (
                      <DashboardWidget
                        showHeader={widget.showHeader}
                        user={user}
                        advisor={advisor}
                        partner={partner}
                        cobForm={cobForm}
                        customerState={customerState}
                        setCustomerState={setCustomerState.bind(this)}
                        caseFilter={state.caseFilter}
                        onShareOpen={props.onShareOpen}
                        setCaseFilter={this._setCaseFilter}
                        nextStepOptions={nextStepOptions}
                        caseFilterContext ={{
	                        caseFilter: state.caseFilter || null,
	                        setCaseFilter: this._setCaseFilter,
	                        cobForm,
                        }}
                      />
                    )}
                    {widget.component === "CustomersTable" && (
                      <CustomersTable
                        inline
                        showHeader={widget.showHeader}
                        width={widget.width}
                        partner={partner}
                        user={user}
                        advisor={advisor}
                        customers={customers}
                        setCustomerState={setCustomerState.bind(this)}
                        caseFilterContext ={{
                          caseFilter: state.caseFilter || null,
                          setCaseFilter: this._setCaseFilter,
                          cobForm,
                        }}
                        nextStepOptions={nextStepOptions}
                      />
                    )}
                    {widget.component === "InitSession" && (
                      <InitSession
                        inline
                        showHeader={widget.showHeader}
                        width={widget.width}
                        busy={busy}
                        user={user}
                        advisor={advisor}
                        partner={partner}
                        customerState={customerState}
                        setCustomerState={setCustomerState.bind(this)}
                        assentlyStatus={assentlyStatus}
                        nextStepOptions={nextStepOptions}
                        onWorkflowSwitched={onWorkflowSwitched}
                      />
                    )}
                    {widget.component === "UploadAndSign" && user.hasPermission("standalone.send") && (
                      <UploadAndSign
                        widget
                        inline
                        showHeader={widget.showHeader}
                        width={widget.width}
                        partnerSsn={advisor.ssn}
                      />
                    )}
                    {widget.component === "Fullmaktskollen" && user.hasPermission("fmk.send") && (
                      <Fullmaktskollen
                        inline
                        widget
                        showHeader={widget.showHeader}
                        width={widget.width}
                        customerIdentityNumber={customerState.ssnOrg ? formatSSN(customerState.ssnOrg, {withDash: false}) : null}
                        user={advisor}
                      />
                    )}
                </Fragment>
              );
            })}
          </div>
        )}
        <>
          <input type="hidden" name="customer_social_security_number" value={customerState.ssnOrg ? formatSSN(customerState.ssnOrg, {withDash: false}) : null}/>
          <input type="hidden" name="checkbox_company" value={customerState.isCompany ? "yes" : "no"} />
          <input type="hidden" name="caseId" value={customerState.selectedCaseId || ""}/>
        </>
      </div>
    )
	}

	_onToggleDashboard = option =>
	{
    const initialState = _getInitialState.call(this);
		this.setState({...initialState, dashboardSelected: option});
    this.props.setAppState({customerState: initialState.customerState, dashboardSelected: option});
	};

  _setCaseFilter = (dashboard, filter) => 
  {
    // If no dashboard or filter is provided, reset the case filter
    if (!dashboard || !filter) {
      return this.setState({
        caseFilter: {
          sessions: "all",
          poa: "all",
          futur: "all",
          kyc: "expired",
          other: "all"        
        }
      });
    }

    this.setState({
      caseFilter: {
        ...this.state.caseFilter,
        [dashboard]: filter
      }
    });
  }
}


// PRIVATE FUNCTIONS
function _getInitialState()
{
	const {customer, partner} = this.props;
	let newState = getViewInitialStateForCustomer(customer, partner);
	newState.isFulfilled = this.isFulfilled(newState);
	return newState;
}

export function getViewInitialStateForCustomer(customer, partner)
{
	let initialState = {
		isLoading: !customer,
		dashboardSelected: "start",
		isFulfilled: false,
		customerState: {
			isCompany: customer ? customer.isCompany : false,
			ssnOrg: (!!customer && customer.getData("customer_social_security_number")) || "",
			selectedOption: null,
			caseListFiltering: null,
			casesLoading: false,
			cases: null,
			selectedCaseId: null,
			selectedCaseIndex: -1,
			selectedCaseIsDraft: null,
			selectedCaseIsRecalled: null,
			showAltMessage: null,
			standaloneApplicationsChecked: [],
			futurCaseId: null,
			futurCaseIndex: -1,
			futurCaseLoading: false,
			orderInstruktionCaseId: null,
			orderInstruktionCaseIndex: -1,
			orderInstruktionSent: false,
			orderInstruktionLoading: false
		}
	};

	return initialState;
}

// Create a wrapper outside the main React component hierarchy using React Portals
const MenuDrawerPortalWrapper = (props) => {
  return ReactDOM.createPortal(
    <MenuDrawer 
      dashboardSelected={props.dashboardSelected}
      user={props.user}
      modules={props.modules}
      onToggle={props.onToggle}
      setCaseFilter={props.setCaseFilter}
    />,
    // document.body // Appends to the body, making it relative to the whole page
    document.getElementById('leApp')
  );
};