import React from "react";
import PropTypes from "prop-types";

import { isEmptyObject } from "@helpers/utils";

import {
  Modal as MuiModal,
  Box,
  TextField,
  Typography,
} from "@mui/material";

// import Button from "@components/Button/Button";
import ModalContentBox from "@mui-theme/styled/ModalContentBox";
import CloseModalBox from "@mui-theme/styled/CloseModalBox";
import ThemedButton from "@mui-theme/styled/ThemedButton";

import useEditNoteModal from "./useEditNoteModal";

const EditNoteModal = props => {
  const {
    open,
    fetching,
    toggle,
    newNote,
    setNewNote,
    inputErrors,
    setInputErrors,
    canSubmitForm,
    handleSubmit
  } = useEditNoteModal(props);

  return (
    <MuiModal
      open={open}
      onClose={(_, reason) => {
        if (reason !== "backdropClick") {
          toggle();
        }
      }}
      sx={{
        overflow: "scroll"
      }}
    >
      <ModalContentBox
        sx={{
          maxHeight: "96%"
        }}
      >
        <Box
          sx={{
            width: "100%",
            justifyContent: "flex-start"
          }}
        >
          <Box
            sx={{
              width: "100%",
              px: 2,
              alignItems: "flex-start"
            }}
          >
            <Typography variant="h4" color="common.black">Redigera anteckning</Typography>
          </Box>
          <Box
            component="form"
            noValidate
            sx={{
              width: "100%",
              alignItems: "flex-start",
              display: "flex",
              flexFlow: "column",
              px: 2
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "flex-start"
              }}
            >
            <TextField
              value={newNote || ""}
              placeholder={newNote?.length < 1 ? "Skriv din anteckning här" : ""}
              required
              fullWidth
              multiline
              rows={6}
              // maxRows={10}
              id="note"
              name="note"
              autoComplete="note"
              error={!!inputErrors?.note}
              onChange={e => {
                if (inputErrors?.note) {
                  setInputErrors(null);
                }
                setNewNote(e && e.target && e.target.value);
              }}
              helperText={inputErrors?.note}
              sx={{
                mb: 2
              }}
            />
            </Box>
          </Box>
        </Box>
        <CloseModalBox
          sx={{
            px: 2
          }}
        >
          <ThemedButton
            variant="outlined"
            onClick={toggle}
            sx={{
              minWidth: "unset",
              width: 100
            }}
          >
            Avbryt
          </ThemedButton>
          <ThemedButton
            variant="contained"
            loading={fetching}
            disabled={
              !canSubmitForm || (inputErrors && !isEmptyObject(inputErrors))
            }
            onClick={handleSubmit}
            sx={{
              minWidth: "unset",
              width: 150
            }}
          >
            Spara ändringar
          </ThemedButton>
        </CloseModalBox>
      </ModalContentBox>
    </MuiModal>
  );
};

EditNoteModal.propTypes = {
  open: PropTypes.bool
};

export default EditNoteModal;
