import { useState, useEffect } from "react";

// HELPERS
import api from "@helpers/api";

const useLogsTable = props => {
  const { client } = props;

  const [loaded, setLoaded] = useState(false);
  const [copyList, setCopyList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const requestSearch = searched => {
    if (page > 0) setPage(0);
    const searchString = searched.toLowerCase();
    setCopyList(
      copyList.filter(
        log =>
          log.CaseId?.toLowerCase().includes(searchString) ||
          log.OriginatorName.toLowerCase().includes(searchString) ||
          log.OriginatorPersonalNumber?.toLowerCase().includes(searchString)
      )
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      const clientId = client.client_id || client.clientId;
      const log = await api("client/auditlog", { clientId });

      // set the event description for each log based on the action
      log.forEach(entry => {
        switch (entry.Action) {
          case "opened":
              entry.EventDescription = `Rådgivningsärende öppnades av ${entry.OriginatorName} (${entry.CaseId})`;
            break;        
          case "created":
              entry.EventDescription = `Rådgivningsärende skapades av ${entry.OriginatorName} (${entry.CaseId})`;
            break;        
          case "signed":
              // check if the client signed the case or a third party
              entry.EventDescription = entry.ClientId.replace(/-/g, "") === entry.OriginatorPersonalNumber ?`Rådgivningsärende signerades av kund (${entry.CaseId})`: `Rådgivningsärende signerades av ${entry.OriginatorName} (${entry.CaseId})`;
            break;
          default:
            break;
        }
        // partner agnostic standalone applications logs
        switch (true) {
          case entry.Action.includes("ag-created"):
            // check if the client signed the case or a third party
            entry.EventDescription = `Nytt Autogiro skapat av ${entry.OriginatorName} (${entry.CaseId})`;
            break;
          case entry.Action.includes("kyc-created"):
              // check if the client signed the case or a third party
              entry.EventDescription = `Nytt KYC skapat av ${entry.OriginatorName} (${entry.CaseId})`;
            break;
          case entry.Action.includes("futur-created"):
              // check if the client signed the case or a third party
              entry.EventDescription = `Ny ansökan om insättnings- eller kapitalförsäkring skapad av ${entry.OriginatorName} (${entry.CaseId})`;
            break;
          case entry.Action.includes("kundinfo-created"):
              // check if the client signed the case or a third party
              entry.EventDescription = `Ny begäran om ändring av kundinformation skapad av ${entry.OriginatorName} (${entry.CaseId})`;
            break;
          default:
            break;
        }
      });
      

      setCopyList(log);
      setLoaded(true);
    };
    if (!loaded) fetchData();

  }, [loaded, client.id])
  


  return {
    loaded,
    copyList,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    handleChangePage,
    requestSearch,
  };
};

export default useLogsTable;