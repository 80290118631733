import React, {Fragment} from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import MUI_TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Link from "@mui/material/Link";

import { getComparator, tableHeightPerTotalPages } from "@helpers/muiTable";
import i18n from "@helpers/i18n";

import ContentBox from "@components/ContentBox/ContentBox";
import ActivityIndicator from "@components/ActivityIndicator/ActivityIndicator";
import CustomTableFooter from "@cobComponents/CustomTableFooter";
import searchIcon from "@assets/img/search_icon.svg";
import TableExpanderIcon from "@components/TableExpanderIcon";
import ExpandableRow from "@views/Dashboard/ExpandableRow";

import useOtherTable from "./useOtherTable";
import CustomerModal from "@cobComponents/CustomerModal/CustomerModal";
import AddCustomerModal from "@cobComponents/AddCustomerModal";
import DashboardTabs from "../DashboardTabs";
import EnhancedTableHead from "./EnhancedTableHead";

const OtherTable = props => {
  const {
    user,
    partner,
    advisor,
    type,
    modalWidget,
    onMessage,
    onShareOpen,
    loaded,
    tabs,
    columns,
    copyList,
    numCases,
    page,
    setPage,
    searchString,
    setSearchString,
    order,
    orderBy,
    rowsPerPage,
    setRowsPerPage,
    setSelectedCustomer,
    handleChangePage,
    handleRequestSort,
    requestSearch,
    handleEntryClick,    
    onAction,
    caseFilter,
    setCaseFilter,
    currentAction,
    resetPaginationAndFilters,
    customerModalOpen,
    selectedCustomer,
    handleClientClick,
    handleClientModalClose,
    updateCustomers,
    caseFilterContext,
    addCustomerModalOpen,
    toggleAddCustomerModal,
    setCustomerState,
    nextStepOptions
  } = useOtherTable(props);

  return (
    <ContentBox
      style={{
        display: "inline",
        maxHeight: "70vh",
        boxShadow: "none",
        margin: 0,
        padding: 0
      }}
    >
      <Box 
        sx={{
          width: "100%",
          alignItems: "flex-start"
        }}
      >          
        <Box
          sx={{
            display: "flex",
            flexDirection: {xs: "column", md: "row"},
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%"
          }}
        >
          <DashboardTabs 
            type="other" 
            translationGroup="dashboard_sessions"
            tabs={tabs}
            fetchingData={!loaded}
            setPage={setPage}
            setSearchString={setSearchString}
            caseFilter={caseFilter}
            setCaseFilter={setCaseFilter}
          />
          <MUI_TextField
            size="small"
            placeholder="Sök..."
            type="search"
            onInput={e => requestSearch(e.target.value)}
            InputProps={{
              value: searchString,
              startAdornment: (
                <InputAdornment position="start">
                  <Box
                    component="img"
                    sx={{
                      height: 20,
                      width: 20,
                      paddingBottom: 0.25
                    }}
                    alt="logo"
                    src={searchIcon}
                  />
                </InputAdornment>
              ),
              sx: {
                px: 3,
                fontSize: "13px",
                borderRadius: "20px",
                width: "100%",
              }
            }}
            sx={{ 
              width: {xs: "100%", md: "30%"},
            }}
          />
        </Box>
        <TableContainer component={Box} sx={{ overflowY: "hidden"}}>
          <Box
            sx={{
              display: "block",
              width: "100%",
              minHeight: tableHeightPerTotalPages[rowsPerPage] || "unset",
              ...(modalWidget && {maxHeight: "60vh"}),
              mt: 1,
              border: 0,
              overflow: "auto",
              overflowY: "hidden"
            }}
          >
            <Table
              stickyHeader
              aria-label="Other Table"
              className="mui-table"
              sx={{
                minWidth: 1100,
                border: 0,
                tableLayout: "fixed"
              }}>
              <EnhancedTableHead
                columns={columns}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {!loaded ? (
                  <TableRow
                    sx={{
                      height: "100%",
                      "&:last-child td": { border: 0 }
                    }}
                  >
                    <TableCell colSpan={columns.length}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: tableHeightPerTotalPages[rowsPerPage]*0.7
                        }}
                      >
                        <ActivityIndicator color="#4cb7d5"  busy/>
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <Fragment>
                    {copyList && copyList.sort(getComparator(order, orderBy))
                      .map((entry, key) => {
                        const msgTitleRef = React.createRef();
                        const msgTextRef = React.createRef();

                        const isExpandable = 
                          (type === "main" && entry.state === "draft" && (user.hasPermission("case.create") || user.ssn === entry.ssn.replaceAll("-", ""))) ||
                          (entry.state === "sent" || entry.state === "signed" || entry.state === "recalled") ||
                          (type === "main" && entry.state === "processing" && user.hasPermission("case.change_state")) ||
                          (user.role !== "assistant" && !entry.is_shared_case && onShareOpen !== undefined) ||
                          ((entry.state === "processing" || entry.state === "sent") && (user.hasPermission("case.msgs_write") || entry.messages.length > 0)) ||
                          entry?.signatories?.length > 0 ||
                          entry.commission_sharing && entry.commission_sharing !== "" ||
                          (entry.message_to_bo !== null && entry.message_to_bo !== "") ||
                          entry.audit.length > 0 && user.hasPermission("case.audit_log");                        
                          
                        let entryState = tabs.find(tab => tab.key === entry.state);
                                      
                        return (
                          <Fragment key={key}>
                            <TableRow
                              sx={{
                                height: 48,
                                ...(key % 2 !== 0 && { "& > td": {background: "#f5f9ff" }}),
                              }}
                            >
                              <TableCell 
                                sx={{
                                  width: "2.5%",
                                  pl: {xs: 0.5, md: 1},
                                  pr: 0,
                                  py: 0.5,
                                  borderBottom: "none",
                                }}
                              >
                                {isExpandable && (
                                  <Box
                                    onClick={() => handleEntryClick(entry.caseId)}
                                    sx={{
                                      cursor: "pointer"
                                    }}
                                  >
                                    <TableExpanderIcon
                                      toggled={entry?.toggled}
                                    />
                                  </Box>
                                )}
                              </TableCell>
                              <TableCell sx={{
                                px: 0,
                                py: 0.5,
                                borderBottom: "none",
                                color: "secondary.lighter",
                                textAlign: "left",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                paddingLeft: "10px"
                              }}>
                                <Box sx={{
                                  display: "inline-block",
                                  backgroundColor: entryState.color,
                                  borderRadius: "9px",
                                  alignContent: "center",
                                  padding: "4px 8px",
                                  lineHeight: "10px",
                                        border: "1px solid #EFEFEF"
                                }}>
                                  <span style={{
                                    fontSize: "10px",
                                    textTransform: "uppercase",
                                    fontWeight: "bold",
                                    letterSpacing: "0.3px",
                                    color: "#686f77"
                                  }}>
                                      {i18n("dashboard_sessions", entryState.translationKey)}
                                  </span>
                                </Box>
                              </TableCell>
                              <TableCell
                                sx={{
                                  px: 0,
                                  py: 0.5,
                                  borderBottom: "none",
                                  color: "secondary.lighter"
                                }}
                              >
                                <Typography variant="tableCell">
                                  {entry.type}
                                </Typography>
                              </TableCell>
                              {user.role !== "advisor" && (
                                <TableCell
                                  sx={{
                                    px: 0,
                                    py: 0.5,
                                    borderBottom: "none",
                                    color: "secondary.lighter",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                  }}
                                >
                                  <Typography variant="tableCell">
                                    {entry.advisorName}
                                  </Typography>
                                </TableCell>
                              )}
                              <TableCell
                                sx={{
                                  px: 0,
                                  py: 0.5,
                                  borderBottom: "none",
                                  color: "secondary.lighter",
                                }}
                              >
                                <Typography variant="tableCell">
                                  {entry.ssn}
                                </Typography>
                              </TableCell>
                              <TableCell
                                  sx={{
                                    px: 0,
                                    py: 0.5,
                                    borderBottom: "none",
                                    color: "secondary.lighter",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                  }}
                                >
                                  <Typography variant="tableCell">
                                    {!modalWidget ? (
                                      <Link variant="tableCell"
                                        href="#"
                                        onClick={handleClientClick(entry.ssn)}
                                        sx={{
                                          textDecoration: "none", "span": {
                                            textDecoration: "underline"
                                          }
                                        }}
                                      >
                                        <span>{entry.name}</span>
                                      </Link>
                                    ) : (
                                      <span>{entry.name}</span>
                                    )}
                                    {entry.workflow === 'futur' && (
                                      <em style={{ display: "block", color: "#95a4ac" }}>Depåöpping inför tecknande av KF</em>
                                    )}

                                    {entry.is_shared_case && (
                                      <em style={{ display: "block", color: "#95a4ac" }}>Huvudrådgivare: {entry.advisorName} </em>
                                    )}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    width: user.role !== "advisor" ? "12.5%" : "15%",
                                    px: 0,
                                    py: 0.5,
                                    borderBottom: "none",
                                    color: "secondary.lighter",
                                  }}
                                >
                                  <Typography variant="tableCell">
                                    {entry.changed}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    width: user.role !== "advisor" ? "12.5%" : "15%",
                                    px: 0,
                                    py: 0.5,
                                    borderBottom: "none",
                                    color: "secondary.lighter",
                                  }}
                                >
                                  <Typography variant="tableCell">
                                    {entry.sent}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    width: user.role !== "advisor" ? "10%" : "12.5%",
                                    px: 0,
                                    pr: 1,
                                    py: 0.5,
                                    borderBottom: "none",
                                    color: "secondary.lighter",
                                    textAlign: "right"
                                  }}
                                >
                                  <Typography variant="tableCell">
                                    {entry.signed}
                                  </Typography>
                                </TableCell>
                            </TableRow>
                            {entry?.toggled && (
                              <ExpandableRow
                                colSpan={columns.length}
                                entry={entry}
                                user={user}
                                partner={partner}
                                type={type}
                                msgTitleRef={msgTitleRef}
                                msgTextRef={msgTextRef}
                                onShareOpen={onShareOpen}
                                onAction={onAction}
                                currentAction={currentAction}
                                resetPaginationAndFilters={resetPaginationAndFilters}
                              />
                            )}                  
                          </Fragment>
                        )
                      })
                    }
                  </Fragment>
                )}
              </TableBody>
            </Table>
          </Box>
          <CustomTableFooter
            loaded={loaded}
            type="other"
            totalCases={numCases}
            data={copyList}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage === -1 && numCases > 100 ? 100 : rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            handleChangePage={handleChangePage}
          />
        </TableContainer>
      </Box>
      {(customerModalOpen && selectedCustomer) && (
        <CustomerModal
          view="overview"
          visible={true}
          user={user}
          partner={partner}
          advisor={advisor}
          client={selectedCustomer}
          onClose={handleClientModalClose}
          updateCustomers={updateCustomers}
          caseFilterContext={caseFilterContext}
          addCustomerModalOpen={addCustomerModalOpen}
          toggleAddCustomerModal={toggleAddCustomerModal}
          setCustomerState={setCustomerState}
          nextStepOptions={nextStepOptions}
          setSelectedCustomer={setSelectedCustomer}
        />
      )}
      {addCustomerModalOpen && (
        <AddCustomerModal
          open={addCustomerModalOpen}
          toggleModal={toggleAddCustomerModal}
          customer={selectedCustomer}
          userData={{personal_number: advisor.ssn, role: advisor.role}}
          updateCustomers={updateCustomers}
          allowNoCustomerId={partner._data.allowNoCustomerId}
        />
      )}
    </ContentBox>
  );
};

export default OtherTable;