import React, { Fragment } from "react";

import { Box, Tab, Tabs } from "@mui/material";

import useDashboardReports from "./useDashboardReports";

const DashboardReports = props => {
  const { reports, currentTab, handleTabChange } = useDashboardReports(props);

  return (
    <Fragment>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "left",
          marginBottom: 2
        }}
      >
        {reports.map((report, index) => {
          const ReportComponent = report.component;

          if (!ReportComponent) {
            return null;
          }

          return (
            <Fragment key={report.key}>
              {currentTab === report.key && <ReportComponent />}
            </Fragment>
          );
        })}
      </Box>
    </Fragment>
  );
};

export default DashboardReports;
