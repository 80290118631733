import React from "react";
import { IntlProvider } from 'react-intl';
import { DateRangePicker, CustomProvider  } from "rsuite";
import svSE from "rsuite/locales/sv_SE"; // Import Swedish locale
// import "rsuite/dist/rsuite.min.css"; // Moved to index.jsx to run before and not override app styles

import { Box, Link, Typography, CircularProgress } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";

import ContentBox from "@components/ContentBox/ContentBox";
import useSignedVolume from "./useSignedVolume";
const { allowedRange } = DateRangePicker;

const SignedVolume = props => {
  const {
    loaded,
    error,
    ranges,
    dateRange,
    report,
    contentRef,
    handlePrint,
    handleDateChange,
    getPrintPageMargins
  } = useSignedVolume(props);

  return (
    <Box
      ref={contentRef}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "start",
        marginBottom: { xs: 2, md: 4 }
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center"
        }}
      >
        <Typography
          variant="sectionHeader"
          sx={{
            color: "primary.main",
            marginBottom: { xs: 1, md: 2 },
              "@media print": {
                  marginBottom: { xs: 2, md: 4 },
              }
          }}
        >
          Signerad volym
        </Typography>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "start",
            marginBottom: { xs: 1, md: 2 }
          }}
        >
          <Typography
            variant="body1"
            sx={{
                fontSize: "12px",
                fontWeight: "bold",
              color: "#003d50",
                mb: "2px",
                "@media print": {
                   display: "none"
                }
            }}
          >
            Vald period:
          </Typography>
          <IntlProvider locale="sv">
            <CustomProvider locale={svSE}>
              <DateRangePicker
                disabled={!loaded}
                cleanable={false}
                isoWeek={true}
                // locale={locale}
                format="yyyy/MM/dd"
                character=" – "
                value={dateRange}
                onChange={handleDateChange}
                placeholder="Välj datumintervall"
                shouldDisableDate={allowedRange(
                  new Date("2023-01-01"),
                  new Date()
                )}
                ranges={ranges}
              />
            </CustomProvider>
          </IntlProvider>
        </Box>
        <Box
          sx={{
            display: "flex",
              alignSelf: "flex-end",
              marginBottom: "16px"
          }}
        >
          <Link
            underline="none"
            color="#4cb7d5"
            onClick={handlePrint}
            sx={{
              display: "flex",
                alignItems: "center",
              cursor: "pointer",
              //add media print to hide print button
              "@media print": {
                display: "none"
              }
            }}
          >
            <PrintIcon
              sx={{
                color: "#000",
                fontSize: "16px",
                marginRight: 1
              }}
            />
            <Typography
              variant="body1"
              sx={{
                color: "primary.main",
                  fontSize: "12px",
                  lineHeight: "12px"
              }}
            >
              <span> Skriv ut / Spara som PDF</span>
            </Typography>
          </Link>
        </Box>
      </Box>
      {error && (
          <div
            className="sign-on-screen-notice Notice warning custom-message"
            style={{ maxWidth: "unset", marginBottom: "20px" }}
          >
            <Typography variant="body2" color="primary.main">
              <b>{error}</b>
            </Typography>
          </div>
        )}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          marginBottom: 2
        }}
      >
        {!loaded ? (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <CircularProgress color="button" size={36} />
          </Box>
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              "@media print": {
                flexDirection: "column",
                justifyContent: "flex-start"
              },
              alignItems: "flex-start",
              justifyContent: "space-between"
            }}
          >
            <ContentBox
              style={{
                minWidth: "48.5%",
                margin: 0
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #ededed",
                  pb: 1.5,
                  mb: 2
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "18px",
                    fontWeight: 500,
                    color: "primary.main",
                    lineHeight: 0.9
                  }}
                >
                  Volym, insättningar
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "space-between"
                }}
              >
                <Typography color="inherit" variant="body1">
                  Antal signerade avtal:
                  <span> {report.numberSales}</span>
                </Typography>
                <Typography color="inherit" variant="body1">
                  Total volym:
                  <span> {report.totalSales}</span>
                </Typography>
              </Box>
              {report.salesPerAdvisor && Object.keys(report.salesPerAdvisor).length > 0 && (
                <>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "flex-start",
                      mt: "20px"
                    }}
                  >
                    <Typography color="inherit" variant="body1" sx={{fontWeight: "bold", fontSize: "16px"}}>
                      Per rådgivare
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      mt: 0.6
                    }}
                  >
                    {Object.keys(report.salesPerAdvisor).map(
                      (advisor, index) => (
                        <Box
                          key={index}
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between"
                          }}
                        >
                          <Typography
                            color="inherit"
                            variant="body1"
                          >
                            {advisor}:
                          </Typography>
                          <Typography
                            color="inherit"
                            variant="body1"
                            sx={{ width: "60%" }}
                          >
                            {report.salesPerAdvisor[advisor]}
                          </Typography>
                        </Box>
                      )
                    )}
                  </Box>
                </>
              )}
            </ContentBox>
            <ContentBox
              className="print-styles"
              style={{
                minWidth: "48.5%",
                margin: 0,
                marginLeft: "2%"
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottom: "1px solid #ededed",
                  pb: 1.5,
                  mb: 2
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "18px",
                    fontWeight: 500,
                    color: "primary.main",
                    lineHeight: 0.9
                  }}
                >
                  Volym, omallokeringar
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "space-between"
                }}
              >

                  <Typography color="inherit" variant="body1">
                      Antal signerade avtal:
                      <span> {report.numberReallocations}</span>
                  </Typography>
                  <Typography color="inherit" variant="body1">
                      Total volym:
                      <span> {report.totalReallocations}</span>
                  </Typography>
              </Box>
              {report.reallocationsPerAdvisor && Object.keys(report.reallocationsPerAdvisor).length > 0 && (
                <>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "flex-start",
                      mt: "20px"
                    }}
                  >
                      <Typography color="inherit" variant="body1" sx={{fontWeight: "bold", fontSize: "16px"}}>
                          Per rådgivare
                      </Typography>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      mt: 0.6
                    }}
                  >
                    {Object.keys(report.reallocationsPerAdvisor).map(
                      (advisor, index) => (
                        <Box
                          key={index}
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between"
                          }}
                        >
                            <Typography
                                color="inherit"
                                variant="body1"
                            >
                                {advisor}:
                            </Typography>
                            <Typography
                                color="inherit"
                                variant="body1"
                                sx={{ width: "60%" }}
                            >
                                {report.reallocationsPerAdvisor[advisor]}
                            </Typography>
                        </Box>
                      )
                    )}
                  </Box>
                </>
              )}
            </ContentBox>
          </Box>
        )}
      </Box>
      <style>{getPrintPageMargins()}</style>
      <style>
        {`
          @media print {
            .print-styles {          
              margin: 25px 0px 0px 0px !important;
            };  
          }
        `}
      </style>
    </Box>
  );
};

export default SignedVolume;
