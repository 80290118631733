import { useState, useEffect } from "react";

import api from "@helpers/api";

const useEditNoteModal = props => {
  const { open, toggle, note, updateNotes, setServerMessage } = props;

  const [fetching, setFetching] = useState(false);
  const [newNote, setNewNote] = useState(note?.Note || "");
  const [inputErrors, setInputErrors] = useState({});
  const [canSubmitForm, setCanSubmitForm] = useState(false);

  useEffect(() => {
    setCanSubmitForm(newNote?.length > 0);
  }, [newNote]);

  const handleSubmit = async e => {
    if (!newNote) {
      return setInputErrors({
        note: "Anteckningen får inte vara tom"
      });
    }

    if (newNote === note.Note) {
      return toggle(null);
    }

    try {
      setFetching(true);

      const response = await api(
        "client/notes/edit",
        { text: newNote, noteId: note.NoteId, advisorId: note.AdvisorId },
        {},
        true
      );
      const { success } = response;
      setFetching(false);

      if (success) {
        updateNotes({
          ...note,
          Note: newNote,
          Created: new Date().toLocaleString("sv-SE", {
            timeZone: "Europe/Stockholm"
          })
        }, "edit");
        setServerMessage({
          type: "success",
          text: "Anteckningen har uppdaterats."
        });
        return toggle(null);
      } else {
        setServerMessage({
          type: "error",
          text: "Något gick fel. Kontakta supporten."
        });
        return toggle(null);
      }
    } catch (error) {
      setFetching(false);
      setServerMessage({
        type: "error",
        text: "Något gick fel. Kontakta supporten."
      });
      console.error(error);
      return toggle(null);
    }
  };

  return {
    open,
    fetching,
    toggle,
    newNote,
    setNewNote,
    inputErrors,
    setInputErrors,
    setServerMessage,
    canSubmitForm,
    handleSubmit
  };
};

export default useEditNoteModal;
